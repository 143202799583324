/* eslint jsdoc/require-param: ["warn", {"enableFixer": false}] -- Solve the remaining cases please. */
/**	NOTE: Do not make this service more complex than it already is */
/* eslint complexity: ["error", 21] -- This disable-line description has been added when we enabled 'eslint-comments/require-description' */
import { Location } from '@angular/common';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationExtras, NavigationSkipped, Router, UrlTree } from '@angular/router';
import { SalesTabNames } from '@plano/client/sales/sales-tab-names.enum';
import { SchedulingApiShift, SchedulingApiShifts } from '@plano/client/scheduling/shared/api/scheduling-api-shift.service';
import { sortShiftsForListViewFns } from '@plano/client/scheduling/shared/api/scheduling-api.utils';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { WorkModelsTabNames } from '@plano/client/work-models/work-models-tab-names.enum';
import { SchedulingApiLeave, SchedulingApiService } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { PScrollToSelectorService } from '@plano/shared/core/scroll-to-selector.service';
import { runOnlyOnMobileApps } from '@plano/shared/core/utils/mobile-utils';
import { PSentryService } from '@plano/shared/sentry/sentry.service';
import { getCurrentScope } from '@sentry/angular-ivy';
import { Subject, Subscription } from 'rxjs';

// TODO: 	Create a type that includes all possible routes.
// 				Problem: Some possible routes are unified by lazy loaded parts.
//				Probably not possible to get lazy children as readonly array.
// 				IDEA: I can probably switch to relative paths everywhere to fix the typing issue above.
// 				type PossibleClientRoutes = typeof CLIENT_ROUTES[number]['path'] |
//				`${typeof CLIENT_ROUTES[number]['path']}/${typeof CLIENT_ROUTES[number]['children'][number]['path']}`
// 				type PossibleAdminRoutes =
//				typeof ADMIN_ROUTES[number]['path'] |
//				`${typeof ADMIN_ROUTES[number]['path']}/${typeof ADMIN_ROUTES[number]['children'][number]['path']}`
// 				type PossibleRoutes = '/' | PossibleClientRoutes | PossibleAdminRoutes;

type StoredRoute = { url : string, queryParams ?: { [key : string] : string }, fragment ?: string };

/**
 * Type that holds all information required to do the navigation
 */
export type NavigationHelperInfo = { url : string, navigationExtras : NavigationExtras, forgetRecentHistoryEntry : boolean};

/** A router wrapper, adding extra functions.
 * NOTE: When using [routerLink] in the template be aware of the following:
 * Having routerLink = null prevents the navigation and having routerLink = '.' tells the browser to navigate to the current url.
 * Sometimes we want to use this self url navigation to highlight something on the current page,
 * for example by adding a new fragment which will trigger a scroll to, without changing the url.
 * It also allows us to change queryParams,
 * which we use to open specific tabs of the sidebar when clicking the buttons on the filter dropdown.
 */
@Injectable( { providedIn: 'root' } )
export class PRouterService {
	constructor(
		private router : Router,
		private location : Location,
		private activatedRoute : ActivatedRoute,
		private console : LogService,
		private zone : NgZone,
		private pScrollToSelectorService : PScrollToSelectorService,
		private pSentryService : PSentryService,
		private api : SchedulingApiService,
		private pMomentService : PMomentService,
	) {
		const currentUrl = window.location.href.split(Config.FRONTEND_URL_LOCALIZED)[1];
		const route = this.urlToStoredRoute(currentUrl);
		this.addUrl(route);

		this.setRouterListener();
		this.setSmartphoneAppListener();

		this.zone.runOutsideAngular(() => {
			this.router.events.subscribe((event) => {
				if (this.ignoreCurrentNavigation)
					return;
				this.events.next(event);
			});
		});
	}

	private STORED_URL_BLACKLIST = [
		'client/report/0/0',
		'client/scheduling/month/0',
	] as const;

	/** @see Router#url */
	public get url() : string {
		return this.router.url;
	}

	public storedUrls : StoredUrls = new StoredUrls();

	/**
	 * Events emitted by the router, it filters the events that should be ignored,
	 * according to the ignoreCurrentNavigation boolean
	 */
	public events = new Subject<Event>();

	private setSmartphoneAppListener() : void {
		runOnlyOnMobileApps(() => {
			// navigate back on app back button
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
			window.nsWebViewInterface.on('backPressed', (_data : any) => {
			// WebViewInterface events are not called in angular zone. But routing needs this…
				this.zone.run(() => {
					this.navBack();
				});
			});
		});
	}

	/**
	 * It is possible that e.g p-tabs has silently changed the url. In that case, store
	 * the latest url available as storedPreviousUrl and not the stored .storedCurrentUrl.
	 */
	private setRouterListener() : void {

		this.events.subscribe((event) => {
			if (!(event instanceof NavigationEnd)) return;
			this.storeNewUrl(event.url);
		});
	}

	private storeNewUrl(newUrl : string) : void {
		let queryParams = this.activatedRoute.snapshot.queryParams;

		// if this is the first load we don't want to replace the existing url in the storage
		// except if a tab as been open in a new tab
		const isFirstLoad : boolean = this.storedUrls.length === 0 && queryParams['urlsStorage'];

		if (isFirstLoad) {
			try {
				this.storedUrls = new StoredUrls(JSON.parse(decodeURIComponent(queryParams['urlsStorage'])) as StoredRoute[]);

				const baseUrl = this.router.url.slice(0, Math.max(0, this.router.url.indexOf('?')));
				const baseUrlFragment = this.activatedRoute.snapshot.fragment;
				this.location.replaceState(baseUrlFragment ? `${baseUrl}#${baseUrlFragment}` : baseUrl);
			} catch {
				this.console.error('Could not load url history from query param!');
			}

		}

		// prevent that the queryParam gets stored, which would mess up the scrolling
		queryParams = {...queryParams, bookingCourseSelector: undefined};

		// If required url has not changed, we have nothing todo.
		const route = this.urlToStoredRoute(newUrl);
		if (this.storedUrls.mostRecent !== null && this.storedUrls.mostRecent.url === route.url) return;

		const fragmentInUrl = this.activatedRoute.snapshot.fragment;

		// we don't want to store the fragment, if any, that is present on the url,
		// and the queryParams should be passed separately
		const cleanUrlToBeStored = fragmentInUrl ? newUrl.split('?')[0].replace(`#${fragmentInUrl}`, '') : newUrl.split('?')[0];

		const shouldNotReplaceUrl = isFirstLoad && !queryParams['scrollToTabIfNotInView'];

		const routeToAdd : StoredRoute = {url: cleanUrlToBeStored, queryParams: queryParams};
		if (fragmentInUrl !== null) routeToAdd.fragment = fragmentInUrl;

		this.addUrl(
			routeToAdd,
			shouldNotReplaceUrl ? false : this.router.getCurrentNavigation()?.extras.replaceUrl,
		);
	}

	/**
	 * Turns string url into StoredRoute
	 */
	public urlToStoredRoute(url : string) : StoredRoute {
		const urlTree = this.router.parseUrl(url);
		const urlTreeChildrenPrimary = urlTree.root.children['primary'];
		if (!(urlTreeChildrenPrimary as unknown)) {
			if (Config.APPLICATION_MODE !== 'KARMA') this.console.debug('Strange behavior in RouterService');
			return { url: '/' };
		}
		const urlWithoutParams = `/${urlTreeChildrenPrimary.segments.map(it => it.path).join('/')}`;
		let resultUrl = this.removeTrailingSlash(urlWithoutParams);

		// Make sure the url '/' wil not result in a undefined value
		resultUrl = resultUrl.length === 0 ? '/' : resultUrl;
		const { queryParams } : UrlTree = urlTree;
		return { url: resultUrl, queryParams: queryParams };
	}

	private removeTrailingSlash(input : string) : string {
		if (!input) return input;
		if (!input.endsWith('/')) return input;
		return input.slice(0, Math.max(0, input.length - 1));
	}

	/**
	 * Nav to current history entry.
	 * (e.g. used when last navigation did not appear in history. like then user opens mobile menu)
	 */
	public navToCurrentHistoryEntry() : void {
		if (this.storedUrls.mostRecent === null || this.storedUrls.length <= 1) {
			// eslint-disable-next-line ban/ban -- intended navigation
			void this.navigate([this.getFallbackUrl()], { replaceUrl: true });
			return;
		}
		// eslint-disable-next-line ban/ban -- intended navigation
		void this.navigate([this.storedUrls.mostRecent.url], {queryParams: this.storedUrls.mostRecent.queryParams!});
	}

	/**
	 * @returns The navigation info required to perform the navigation, if there are not stored urls
	 * (i.e. we are on the first loaded page) returns null instead
	 */
	public navBackInfo() : NavigationHelperInfo {
		if (!this.storedUrls.previousUrl || (this.storedUrls.mostRecent === null || this.storedUrls.length <= 1)) {
			// The fallback url will be calculated based on the most recent url.
			// So we need to handle this before we remove urls from store.
			return {url: this.getFallbackUrl(), navigationExtras: {replaceUrl: true}, forgetRecentHistoryEntry: false};
		}
		return {url: this.storedUrls.previousUrl.url,
										navigationExtras: {queryParams: this.storedUrls.previousUrl.queryParams!, replaceUrl: true},
										forgetRecentHistoryEntry: true};
	}

	/**
	 * Navigate back to the previous url in our store.
	 * If our store does not provide a good url, it will calculate some fallback-url.
	 */
	public navBack() : void {
		if (this.storedUrls.mostRecent === null || this.storedUrls.length <= 1) {
			if (Config.platform === 'appAndroid') {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- access window variable
				(window as any).nsWebViewInterface.emit('minimizeNativeApp');
				return;
			}

			// The fallback url will be calculated based on the most recent url.
			// So we need to handle this before we remove urls from store.
			// eslint-disable-next-line ban/ban -- intended navigation
			void this.navigate([this.getFallbackUrl()], { replaceUrl: true });
			return;
		}

		this.forgetMostRecentHistoryEntry();

		const extras : NavigationExtras = {queryParams: this.storedUrls.mostRecent.queryParams!};
		if (this.storedUrls.mostRecent.fragment !== undefined) extras.fragment = this.storedUrls.mostRecent.fragment;

		// eslint-disable-next-line ban/ban -- intended navigation
		void this.navigate(
			[this.storedUrls.mostRecent.url],
			extras,
		);
	}

	/**
	 * Remove the last storedUrl
	 */
	public forgetMostRecentHistoryEntry() : void {
		this.storedUrls.pop();
	}

	/**
	 * Navigate to another Page.
	 * If you don’t want the current url in your history, provide a { replaceUrl: true } as second param.
	 */
	public async navigate(commands : string[], extras ?: NavigationExtras) : ReturnType<Router['navigate']> {
		// eslint-disable-next-line ban/ban -- intended navigation
		return this.router.navigate(commands, extras);
	}

	private isPartOfBlacklist(url : string) : boolean {
		return !!this.STORED_URL_BLACKLIST.some((blacklistItem) => url.includes(blacklistItem));
	}

	/**
	 * @param input Route to be added to the stored routes for posterior navigation
	 * @param replacePrevious Should the new url replace the last stored url?
	 */
	public addUrl(input : StoredRoute, replacePrevious ?: boolean) : void {
		if (this.isPartOfBlacklist(input.url)) return;
		this.storedUrls.addUrl(input, replacePrevious);
	}

	private getFallbackUrlForInterfaceSubPages(mostRecentUrl : string) : string | null {
		const INTERFACES_URL = '/client/plugin/interfaces';
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (mostRecentUrl.match(/^\/?client\/plugin\/boulderado/)) return INTERFACES_URL;
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (mostRecentUrl.match(/^\/?client\/plugin\/freeclimber/)) return INTERFACES_URL;
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (mostRecentUrl.match(/^\/?client\/plugin\/beta7/)) return INTERFACES_URL;
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (mostRecentUrl.match(/^\/?client\/plugin\/routes-manager/)) return INTERFACES_URL;
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (mostRecentUrl.match(/^\/?client\/plugin\/kletterszene/)) return INTERFACES_URL;
		return null;
	}

	/**
	 * Is there any stored previous url?
	 */
	public get historyIsEmpty() : boolean {
		return this.storedUrls.length <= 1;
	}

	private leaveFallbackUrl() {
		let leave : SchedulingApiLeave | null = null;
		if (this.api.currentlyDetailedLoaded === null && this.api.data.aiLeaves.isAvailable) {
			const newLeave = this.api.data.leaves.find(item => item.isNewItem);
			if (newLeave?.leaveDays.length) leave = newLeave;
		} else leave = this.api.currentlyDetailedLoaded as SchedulingApiLeave;
		return leave === null ? '/client/report' : `/client/report/${this.pMomentService.m(leave.start).startOf('month').valueOf()}/${this.pMomentService.m(leave.start).endOf('month').valueOf()}`;
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity -- need lots of ifs for each route
	private getFallbackUrl() : string {
		if (!this.storedUrls.mostRecent) {
			// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
			return Config.IS_MOBILE ? '/client/mobile-sidebar' : '/client';
		}

		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/email\/.+/) !== null) return '/client/plugin/emails';
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/menu\/.+/) !== null) return '/client/mobile-sidebar';
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/notifications\/.+/) !== null) return '/client/scheduling';
		// eslint-disable-next-line require-unicode-regexp, deprecation/deprecation -- This disable-line description has been added when we enabled 'eslint-comments/require-description', TODO: Remove this before you work here.
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/shift-exchange\/.+/) !== null) return Config.IS_MOBILE ? '/client/mobile-sidebar' : '/client/shift-exchanges';
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/workingtime\/.+/) !== null) return '/client/report';
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/work-model\/.+/) !== null) return `/client/work-models/${WorkModelsTabNames.LIST}`;
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/leave\/.+/) !== null) return this.leaveFallbackUrl();
		// eslint-disable-next-line require-unicode-regexp, deprecation/deprecation -- TODO: Remove this before you work here.
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/gift-card\/.+/) !== null) return Config.IS_MOBILE ? '/client/mobile-sidebar' : `/client/sales/${SalesTabNames.GIFT_CARDS}`;
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/transaction\/.+/) !== null) return `/client/sales/${SalesTabNames.TRANSACTIONS}`;
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/booking\/.+/) !== null) return `/client/sales/${SalesTabNames.BOOKINGS}`;
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/permissions\/.+/u) !== null) return `/client/permissions/settings`;

		const fallbackUrlForInterfaceSubPages = this.getFallbackUrlForInterfaceSubPages(this.storedUrls.mostRecent.url);
		if (fallbackUrlForInterfaceSubPages !== null) return fallbackUrlForInterfaceSubPages;

		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\/plugin\/.+\/.+/) !== null) return '/client/plugin';

		// If user is at calendar
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		const calendarRegex = /^(\/?client\/scheduling\/\w+)\/\d+/;
		const match = this.storedUrls.mostRecent.url.match(calendarRegex);
		if (match) {
			return this.storedUrls.mostRecent.url.replace(calendarRegex, `$1/${Date.now()}`);
		}

		// If there is still client in the url, then this is some other sup-page of client.
		// Like shiftmodel, member, testaccount, etc.
		// eslint-disable-next-line require-unicode-regexp -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.storedUrls.mostRecent.url.match(/^\/?client\//)) {
			// In most cases, desk is the go-to page for mobile-devices.
			// eslint-disable-next-line deprecation/deprecation -- FIXME: Remove this before you work here.
			if (Config.IS_MOBILE) return '/client/mobile-sidebar';
			return '/client';
		}

		return '';
	}

	/**
	 * If there is a fragment, then scroll to and animate the target with that id.
	 * @param fragment The fragment provided by the router/url.
	 */
	private dealWithFragment(fragment : string | null) {
		if (!fragment) return;
		this.console.debug(`There is an anchor-link => ${fragment}`);

		const FRAGMENT = this.activatedRoute.snapshot.fragment;
		if (!FRAGMENT) return;

		void this.pScrollToSelectorService.scrollToSelector(
			`#${FRAGMENT}`,
			{
				animate: true,
				ignoreScrollPosition: true,
				waitForApiLoaded: false,
			},
		);
	}

	/**
	 *
	 * @returns A subscription to the router events that sets the transaction name in Sentry to the current path
	 */
	public setNavigationSentryTransactionName() : Subscription {
		return this.events.subscribe((navigationEvent) => {
			if (navigationEvent instanceof NavigationSkipped || navigationEvent instanceof NavigationEnd) {
				getCurrentScope().setTransactionName(this.pSentryService.removeVarsFromText(location.pathname).text);
			}
		});

	}

	/**
	 * Can the current navigation be ignored? Useful when we want to trigger a navigation that shouldn't
	 * trigger any navigation listeners.
	 */
	public get ignoreCurrentNavigation() : boolean {
		return this.router.getCurrentNavigation()?.extras.state?.['ignoreNavigationEvent'] === true;
	}

	/**
	 * Wait for navigation events (skipped or finish) to deal with the fragment if there is one.
	 * For more details, see {@link dealWithFragment}.
	 */
	public setListenerToHandleFragments() : Subscription {
		return this.router.events.subscribe((navigationEvent) => {
			if (navigationEvent instanceof NavigationSkipped || navigationEvent instanceof NavigationEnd) {
				this.dealWithFragment(this.activatedRoute.snapshot.fragment);
			}
		});
	}

	/** returns true if shift with todo could be found on the view */
	public scrollToFirstShift(shifts : SchedulingApiShifts) : boolean {
		// TODO: PLANO-184171 Switch from [...array].reverse() to ES2023’s array.toReversed()
		const firstSelectedShift = shifts.sort([...sortShiftsForListViewFns].reverse(), { inPlace: false }).first;
		return this.scrollToShift(firstSelectedShift);
	}

	/** Scroll to the provided shift */
	private scrollToShift(shift : SchedulingApiShift | null) : boolean {
		if (shift?.id) {
			void this.pScrollToSelectorService.scrollToSelector(`#${shift.scrollToFragment}`, {
				animate: true,
				ignoreScrollPosition: true,
				waitForApiLoaded: false,
			});
			return true;
		}
		return false;
	}

	/**
	 * Remove the fragment from the url without triggering a navigation event.
	 * @param activatedRoute The activated route to remove the fragment from.
	 */
	public async removeFragment(
		activatedRoute : ActivatedRoute,
	) {
		// eslint-disable-next-line ban/ban -- intended navigation
		return this.navigate(['.'], {queryParamsHandling: 'preserve', relativeTo: activatedRoute, replaceUrl: true, state: {ignoreNavigationEvent: true} });
	}
}

/** A class for the router service to store routes in a list. */
class StoredUrls {

	constructor(private storage : StoredRoute[] = []) {
	}

	private logRoutingToBrowserConsole : boolean = false;

	public storedRoutesChanged = new EventEmitter<null>();

	/**
	 * @returns The current storage
	 */
	public getStorageCopy() : StoredRoute[] {
		return [...this.storage];
	}

	/**
	 * Add an item or items to the array of stored routes
	 */
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- FIXME: Remove this before you work here.
	public push(item : StoredRoute) : number | void {
		const inputWithoutTrainingSlash = this.removeTrailingSlash(item);

		/**
		 * TODO: [PLANO-110851]
		 * This should be stored in an array of forbidden urls. See commit e7e58cd8 for possible implementation.
		 * Check if PLANO-46646 is still working afterwards.
		 */
		// Never store start page. I user needs to be navigated to start page, then by fallback, not by navigation history.
		if (inputWithoutTrainingSlash.url === '') return;

		// Visiting this site would log out the user. This should not happen by browsing the history.
		if (inputWithoutTrainingSlash.url === Config.LOGOUT_PATH) return;

		// Make sure there are no duplicate urls next to each other
		if (this.mostRecent !== null && this.mostRecent.url === inputWithoutTrainingSlash.url) return;
		// eslint-disable-next-line no-console -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.logRoutingToBrowserConsole) console.log('+', inputWithoutTrainingSlash);
		const returnValue = this.storage.push(inputWithoutTrainingSlash);
		this.storedRoutesChanged.emit();
		return returnValue;
	}

	/**
	 * Like Array.pop(…)
	 */
	public pop() : StoredRoute | null {
		const poppedItem = this.storage.pop();
		// eslint-disable-next-line no-console, literal-blacklist/literal-blacklist -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		if (this.logRoutingToBrowserConsole) console.log('-', poppedItem);
		this.storedRoutesChanged.emit();

		return poppedItem ?? null;
	}

	/**
	 * Get item from array by index
	 */
	private get(index : number) : StoredRoute | null {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This disable-line description has been added when we enabled 'eslint-comments/require-description'
		return this.storage[index] || null;
	}

	/**
	 * How many items are stored in our 'browser history'
	 */
	public get length() : number {
		return this.storage.length;
	}

	/**
	 * Add a url to our store
	 */
	public addUrl(input : StoredRoute, replacePrevious ?: boolean) : void {
		const inputWithoutTrainingSlash = this.removeTrailingSlash(input);

		// Make sure there are no duplicate urls next to each other
		if (this.mostRecent !== null && this.mostRecent.url === inputWithoutTrainingSlash.url) return;
		if (replacePrevious) this.pop();
		this.push(input);
	}

	/**
	 * Get previous url without popping it from the storage
	 */
	public get previousUrl() : StoredRoute | null {
		return this.get(this.storage.length - 2);
	}

	/**
	 * The most recent url in the array.
	 * If the user jumped with a direct link into the app, the mostRecent equals the current url.
	 */
	public get mostRecent() : StoredRoute | null {
		return this.get(this.storage.length - 1);
	}

	public set mostRecent(input : StoredRoute | null) {
		if (input === null) throw new Error('Can not save route `null` to mostRecent');
		this.pop();
		this.push(input);
	}

	private removeTrailingSlash(input : StoredRoute) : StoredRoute {
		if (!input.url) return input;
		if (!input.url.endsWith('/')) return input;
		input.url = input.url.slice(0, Math.max(0, input.url.length - 1));
		return input;
	}
}
