import { Config } from '@plano/shared/core/config';

/**
 * Run the passed method only on mobile apps.
 * @param method The method to run.
 */
export const runOnlyOnMobileApps = (method : () => void) : void => {
	if (Config.platform === 'appAndroid' || Config.platform === 'appIOS') {
		method();
	}
};
